<template lang="pug">
#page-tariff-expired
  invoice-page
    error-page(empty-description="Ваш тариф истек" status-code="tariff")
      template(#errorMessage) Ваш тариф истек {{ dateTime && formatDateTime(dateTime) }}
      template(#errorDescription) Для продолжения работы оставьте заявку на продление тарифа.
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { parse } from 'date-fns';
import { formatDateTime } from "@/utils/formatter/dateFormatter";

import ErrorPage from "@/components/pages/error/ErrorPage.vue";
import InvoicePage from "~/views/settings/tariff/InvoicePage.vue";

export default defineComponent({
  name: "PageTariffExpired",
  methods: {
    formatDateTime,
  },
  components: {
    InvoicePage,
    ErrorPage,
  },
  setup() {

    const { company } = useUserAccess();
    const dateTime = company.value.dateEndTariff ? parse(company.value.dateEndTariff + ' +0300', 'dd-MM-yyyy HH:mm x', new Date()) : '';

    return {
      dateTime,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#page-tariff-expired {
  @include page-view;
}
</style>
